import React from 'react';
import {
  List,
  ListItem,
  Typography,
  withStyles
} from '@material-ui/core';

const styles = theme => ({
  listItem: {
    justifyContent: 'center',
  }
})

function Info(props) {
  const { classes } = props;

  const who = [
    "Web Developer, Full Stack",
    "Django, .NET, Flutter",
    "Python, VB, C#, Dart",
    "React, React Native",
    "HTML, CSS, JS",
    "AWS, Digital Ocean, Firebase, Heroku, Upcloud",
    "Git, GitHub",
    "Scrum, eCommerce, ERP, Logistics, Production",
  ]
  return (
    <List>
      {who.map(value => (
          <ListItem
            key={value}
            className={classes.listItem}
          >
            <Typography variant="body1">
              { value.trimLeft() }
            </Typography>
          </ListItem>
      ))}
    </List>
  );
}

export default withStyles(styles)(Info);
